import React from 'react';
import { connect } from 'dva';
import { Alert, Input, Spin, Modal } from 'antd';
import {
  VerticalAlignTopOutlined,
  ToTopOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import AppIcon from '@/components/app-icon';
import ChatbotMessage from './chatbot-message';

import './index.less';

class Chatbot extends React.Component {
  state = {
    chatbotInputValue: '',
  };

  componentDidUpdate(prevProps) {
    const {
      chatbotModel: { chatbotVisibile, chatbotMessages, error },
    } = this.props;

    if (prevProps.chatbotModel.chatbotVisibile !== chatbotVisibile) {
      chatbotVisibile ? this.connect() : this.disconnect();
    }

    if (prevProps.chatbotModel.error !== error) {
      this.scrollDown();
    }
  }

  componentWillUnmount() {
    this.disconnect();
  }

  scrollDown = (className) => {
    const dom = document.querySelector('.chatbot-messages-container .ant-spin-container');
    if (!dom) return;

    let scrollTop = dom.scrollTop + dom.scrollHeight;
    const divs = dom.querySelectorAll(className);

    if (divs.length > 0) {
      const lastDiv = divs[divs.length - 1];
      scrollTop = lastDiv.offsetTop - dom.offsetTop;
    }

    dom.scrollTop = scrollTop;
  };

  connect = () => {
    const {
      chatbotModel: {
        chatPlatformInfo,
        chatbotSourceId,
        chatbotConversationId,
        chatbotPubsubToken,
      },
      dispatch,
    } = this.props;

    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) return;

    dispatch({ type: 'chatbotModel/getMessages' }).then(this.scrollDown);

    if (!chatbotSourceId || !chatbotConversationId) return;

    const url = `${chatPlatformInfo.serviceUrl}/cable`.replace(/^http/, 'ws');
    this.websocket = new WebSocket(url);

    this.websocket.onopen = () => {
      const message = JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'RoomChannel',
          pubsub_token: chatbotPubsubToken,
        }),
      });
      this.websocket.send(message);
    };

    this.websocket.onmessage = (msg) => {
      const data = JSON.parse(msg.data);

      if (data.message && data.message.event === 'message.created') {
        dispatch({ type: 'chatbotModel/getMessages' }).then(() => this.scrollDown('.user-message'));
      }
    };

    this.websocket.onclose = () => {
      this.websocket = null;
    };

    this.websocket.onerror = (err) => {
      dispatch({ type: 'chatbotModel/setState', payload: { error: err.data } });
    };
    // If the server wasn't able to respond in 3 seconds then show some error message.
    setTimeout(() => {
      if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
        dispatch({
          type: 'chatbotModel/setState',
          payload: { error: 'WebSocket server unavailable' },
        });
      }
    }, 3000);
  };

  disconnect = () => {
    if (!this.websocket || this.websocket.readyState === WebSocket.CLOSED) {
      this.websocket = null;

      return;
    }

    this.websocket.close();
  };

  getRelatedMessage = (index) => {
    const {
      chatbotModel: { chatbotMessages },
    } = this.props;
    if (index <= 0 || index >= chatbotMessages.length) return null;

    for (let i = index - 1; i >= 0; i--) {
      if (chatbotMessages[i].message_type !== chatbotMessages[index].message_type) {
        return chatbotMessages[i];
      }
    }

    return null;
  };

  render() {
    const {
      chatbotModel: {
        chatbotConversationId,
        chatbotVisibile,
        chatbotDetached,
        chatbotExpanded,
        chatbotMessages,
        loading,
        postMessagesLoading,
        error,
      },
      dispatch,
    } = this.props;
    const { chatbotInputValue } = this.state;

    return chatbotVisibile ? (
      <div
        className={
          'chatbot-container ' +
          (chatbotDetached ? 'chatbot-detached ' : 'chatbot-attached ') +
          (chatbotExpanded ? 'chatbot-expanded ' : '')
        }
      >
        <div className="chatbot-toolbar">
          <div className="chatbot-toolbar-left">
            <AppIcon
              svg="app-bar"
              name="ai-assistant"
            />
            AI Assistant
          </div>
          <div className="chatbot-toolbar-right">
            {!chatbotDetached &&
              (chatbotExpanded ? (
                <VerticalAlignTopOutlined
                  onClick={() => {
                    dispatch({
                      type: 'chatbotModel/setState',
                      payload: {
                        chatbotExpanded: false,
                      },
                    });
                  }}
                />
              ) : (
                <ToTopOutlined
                  onClick={() => {
                    dispatch({
                      type: 'chatbotModel/setState',
                      payload: {
                        chatbotExpanded: true,
                      },
                    });
                  }}
                />
              ))}
            {chatbotDetached ? (
              <ShrinkOutlined
                onClick={() => {
                  dispatch({
                    type: 'chatbotModel/setState',
                    payload: {
                      chatbotDetached: false,
                    },
                  });
                }}
              />
            ) : (
              <ArrowsAltOutlined
                onClick={() => {
                  dispatch({
                    type: 'chatbotModel/setState',
                    payload: {
                      chatbotDetached: true,
                    },
                  });
                }}
              />
            )}
            <CloseOutlined
              onClick={() => {
                const onClose = () => {
                  dispatch({
                    type: 'chatbotModel/setState',
                    payload: {
                      chatbotVisibile: false,
                    },
                  });
                };

                chatbotConversationId
                  ? Modal.confirm({
                      title: 'Confirm:',
                      content: 'Would you like to end the conversation?',
                      cancelText: 'No',
                      okText: 'Yes',
                      cancelButtonProps: { type: 'text' },
                      onCancel: onClose,
                      onOk: () => {
                        dispatch({
                          type: 'chatbotModel/toggleConversationStatus',
                          payload: 'resolved',
                        });
                        onClose();
                      },
                    })
                  : onClose();
              }}
            />
          </div>
        </div>
        <div className="chatbot-body">
          <Spin
            wrapperClassName="chatbot-messages-container"
            spinning={loading || postMessagesLoading}
          >
            {chatbotMessages.map((message, index) => (
              <ChatbotMessage
                key={message.id}
                onFeedback={(feedback) => {
                  /* Matomo */
                  const relatedMessage = this.getRelatedMessage(index);
                  relatedMessage &&
                    dispatch({
                      type: 'userModel/trackUserEvent',
                      payload: {
                        action: 'Ai Assistant',
                        data: {
                          question: relatedMessage.content,
                          time: relatedMessage.created_at,
                          feedback,
                        },
                      },
                    });
                }}
                {...message}
              />
            ))}
            {error && (
              <Alert
                closable
                showIcon
                type="warning"
                message={`Bot encountered an error: ${error}.`}
              />
            )}
          </Spin>
          <div className="chatbot-footer">
            <Input.Search
              placeholder="Place your query here..."
              enterButton={<AppIcon name="send-message-icon" />}
              allowClear
              disabled={postMessagesLoading}
              value={chatbotInputValue}
              onChange={(e) => {
                this.setState({ chatbotInputValue: e.target.value });
              }}
              onSearch={(value) => {
                value &&
                  dispatch({
                    type: 'chatbotModel/postMessages',
                    payload: { content: value },
                    callback: () => {
                      this.connect();
                      this.setState({ chatbotInputValue: '' });

                      /* Matomo */
                      const {
                        chatbotModel: { chatbotMessages: newChatbotMessages },
                      } = this.props;
                      const relatedMessage = newChatbotMessages.findLast(
                        ({ message_type }) => message_type === 0
                      );
                      relatedMessage &&
                        dispatch({
                          type: 'userModel/trackUserEvent',
                          payload: {
                            action: 'Ai Assistant',
                            data: {
                              question: relatedMessage.content,
                              time: relatedMessage.created_at,
                            },
                          },
                        });
                    },
                  });
              }}
            />
            <div className="chatbot-footer-info">
              AI Assistant can make mistakes. Please verify important info.
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default connect(({ chatbotModel }) => ({
  chatbotModel,
}))(Chatbot);
