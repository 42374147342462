import React from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import AppIcon from '@/components/app-icon';
import { ROLE_LIST, DOMAIN_LIST } from '@/constants';
import copyToClipboard from '@/utils/copyToClipboard';
import { ROW_KEY } from '../';

class UserDetails extends React.Component {
  formRef = React.createRef();

  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...this.props.user,
      ...values,
    };

    this.setState({
      isSaveDisabled:
        !this.values.username ||
        !this.values.email ||
        !this.values.firstName ||
        !this.values.lastName ||
        !this.values.roles ||
        !this.values.portalDomain ||
        !this.values.memberOfPortalDomains ||
        !this.values.memberOfPortalDomains.length > 0,
    });
  };

  render() {
    const { user, onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;
    const isEdit = this.props.user[ROW_KEY];

    return (
      <Modal
        visible
        width={600}
        title={`${isEdit ? 'Edit' : 'New'} User`}
        closable={false}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: `userModel/${isEdit ? 'patchUsers' : 'postUsers'}`,
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 6 }}
          ref={this.formRef}
          initialValues={user}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          {!isEdit && (
            <Form.Item
              label="Username"
              name="username"
            >
              <Input />
            </Form.Item>
          )}
          {!isEdit && (
            <Form.Item
              label="Email"
              name="email"
            >
              <Input />
            </Form.Item>
          )}
          {!isEdit && (
            <Form.Item
              label="Email Domain"
              name="emailDomain"
            >
              <Input />
            </Form.Item>
          )}
          {!isEdit && (
            <Form.Item
              label="First Name"
              name="firstName"
            >
              <Input />
            </Form.Item>
          )}
          {!isEdit && (
            <Form.Item
              label="Last Name"
              name="lastName"
            >
              <Input />
            </Form.Item>
          )}
          {!isEdit && (
            <Form.Item
              label="Contact Number"
              name="phone"
            >
              <Input />
            </Form.Item>
          )}
          {!isEdit && (
            <Form.Item
              label="Company"
              name="parentAccountName"
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label="Role(s)"
            name="roles"
          >
            <Select 
             showArrow
             mode="multiple"
             options={ROLE_LIST.map((item) => ({ label: item, value: item }))} />
          </Form.Item>
          <Form.Item
            label="Portal Domain"
            name="portalDomain"
          >
            <Select options={DOMAIN_LIST.map((item) => ({ label: item, value: item }))} />
          </Form.Item>
          <Form.Item
            label="Member of Domains"
            name="memberOfPortalDomains"
          >
            <Select
              showArrow
              mode="multiple"
              options={DOMAIN_LIST.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          {isEdit && (
            <Form.Item label="API Key">
              <Input.Search
                readOnly
                placeholder="Generate API Key"
                enterButton="Generate"
                value={user.apiKey}
                suffix={
                  user.apiKey ? (
                    <AppIcon
                      name="export"
                      onClick={() => copyToClipboard(user.apiKey)}
                    />
                  ) : undefined
                }
                onSearch={() => {
                  Modal.confirm({
                    title: 'Warning',
                    content: 'This will generate new API key.',
                    cancelButtonProps: { type: 'text' },
                    onOk: () => {
                      dispatch({
                        type: 'userModel/patchUsersGenerateApiKey',
                        payload: { username: user.username },
                      });
                    },
                  });
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

export default UserDetails;
