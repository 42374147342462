import React from 'react';
import markdownit from 'markdown-it';
import mila from 'markdown-it-link-attributes';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@/constants';
import AppIcon from '@/components/app-icon';

import './index.less';

export default class ChatbotMessage extends React.Component {
  md = markdownit({
    html: false,
    xhtmlOut: true,
    breaks: true,
    langPrefix: 'language-',
    linkify: true,
    typographer: true,
    quotes: '\u201c\u201d\u2018\u2019',
    maxNesting: 20,
  }).use(mila, {
    attrs: {
      rel: 'noreferrer',
      target: '_blank',
    },
  });

  render() {
    const { message_type, content, created_at, onFeedback } = this.props;
    const isContentValid = typeof content === 'string';

    return (
      <div
        className={
          'chatbot-message-container ' + (message_type === 0 ? 'user-message' : 'agent-message')
        }
      >
        <div className="message-bubble">
          <div
            className="message-content"
            dangerouslySetInnerHTML={{
              __html: this.md.render(isContentValid ? content : ''),
            }}
          />
          <div className="message-footer">
            {created_at && (
              <div className="message-timestamp">
                {moment(created_at * 1000).format(DATE_TIME_FORMAT)}
              </div>
            )}
            {message_type !== 0 && isContentValid && (
              <div className="message-feedback">
                <span>How did I do?</span>
                <AppIcon
                  name="thumb-up"
                  onClick={() => onFeedback('Like')}
                />
                <AppIcon
                  name="thumb-down"
                  onClick={() => onFeedback('Dislike')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
