import {
  getUsersProfile,
  getUserLabDomains,
  patchUsersEulaVersion,
  getUsers,
  getUsersActive,
  postUsers,
  deleteUsers,
  getUsersAdminUsernameList,
  getUsersInstructorList,
  getUsersAuthorizationToolbarIcons,
  patchUsersSwitchDomain,
  patchUsersRole,
  patchUsersRoles,
  patchUsersMemberDomains,
  patchUsersGenerateApiKey,
  getUsersVideoNotificationList,
} from '@/services';
import Cookie from 'js-cookie';
import isEqual from 'lodash/isEqual';
import { message } from 'antd';

const model = {
  namespace: 'userModel',
  state: {
    profile: {},
    adminRole: undefined,
    toolbarIcons: [],
    users: [],
    currentUser: {},
    refineParams: {
      limit: 10,
      page: 1,
    },
    // UI states
    pagination: {},
  },
  subscriptions: {},
  effects: {
    *getUsersProfile(_, { call, put }) {
      try {
        const data = yield call(getUsersProfile);

        yield put({
          type: 'setState',
          payload: {
            adminRole:
              ['Super Admin', 'Admin'].includes(data.role) ||
              (['Training Admin'].includes(data.role) && data.portalDomain === 'TRAINING'),
            analyticsAdminRole:
              ['Super Admin', 'Admin', 'Analytics Admin'].includes(data.role) ||
              (data.roles && data.roles.includes('Analytics Admin')),
          },
        });
        yield put({
          type: 'appModel/setState',
          payload: { validCookie: Cookie.get('authorization') },
        });

        const userLabDomains = yield call(getUserLabDomains, undefined, { user: data.username });
        let labDomainUserTypes = {};
        userLabDomains.forEach(
          ({ domainName, labDomainUserType }) =>
            (labDomainUserTypes[domainName] = labDomainUserType)
        );
        yield put({
          type: 'labModel/setState',
          payload: { labDomainUserTypes },
        });

        yield put({
          type: 'setState',
          payload: {
            profile: {
              ...data,
              labDomains: userLabDomains,
              managedLongTerm:
                !['Super Admin', 'Admin'].includes(data.role) &&
                !userLabDomains.find(({ labDomainUserType }) => labDomainUserType !== 'LONGTERM'),
            },
          },
        });
      } catch (e) {
        yield put({
          type: 'appModel/setState',
          payload: { validCookie: undefined },
        });
        yield put({ type: 'appModel/globalErrorHandler', payload: e });
      }
    },
    *patchUsersEulaVersion(_, { call, select, put }) {
      try {
        const { latestEula } = yield select(({ eulaModel }) => ({
          latestEula: eulaModel.latestEula,
        }));
        yield call(patchUsersEulaVersion, { eulaVersion: latestEula.version });

        yield put({ type: 'getUsersProfile' });

        /* Matomo */
        yield put({ type: 'userModel/trackUserEvent', payload: { action: 'Accept Eula' } });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getUsersAuthorizationToolbarIcons(_, { call, put }) {
      try {
        const data = yield call(getUsersAuthorizationToolbarIcons);
        yield put({
          type: 'setState',
          payload: {
            toolbarIcons: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getUsers({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'userModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ userModel }) => ({
          refineParams: userModel.refineParams,
        }));

        const data = yield call(getUsers, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            users: data.docs,
            currentUser: {},
            pagination: {
              current: data.page,
              pageSize: data.limit,
              total: data.totalDocs,
            },
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'userModel',
            loading: false,
          },
        });
      }
    },
    *getUsersActive({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'userModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ userModel }) => ({
          refineParams: userModel.refineParams,
        }));

        const data = yield call(getUsersActive, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            users: data,
            currentUser: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'userModel',
            loading: false,
          },
        });
      }
    },
    *postUsers({ payload, callback }, { call, put }) {
      try {
        yield call(postUsers, payload);

        yield put({ type: 'getUsers' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchUsers({ payload, callback }, { all, call, select, put }) {
      try {
        const { currentUser } = yield select(({ userModel }) => ({
          currentUser: userModel.currentUser,
        }));

        let calls = [];
        if (!isEqual(currentUser.portalDomain, payload.portalDomain)) {
          calls.push(call(patchUsersSwitchDomain, payload));
        }
        if (!isEqual(currentUser.role, payload.role)) {
          calls.push(call(patchUsersRole, payload));
        }
        if (!isEqual(currentUser.roles, payload.roles)) {
          calls.push(call(patchUsersRoles, payload));
        }
        if (!isEqual(currentUser.memberOfPortalDomains, payload.memberOfPortalDomains)) {
          calls.push(call(patchUsersMemberDomains, payload));
        }

        if (calls.length > 0) {
          yield all(calls);
          yield put({ type: 'getUsers' });
          message.success('Success');
        }

        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchUsersGenerateApiKey({ payload }, { call, select, put }) {
      try {
        const { currentUser } = yield select(({ userModel }) => ({
          currentUser: userModel.currentUser,
        }));

        const data = yield call(patchUsersGenerateApiKey, payload);

        yield put({
          type: 'onCurrentUserChange',
          payload: {
            ...currentUser,
            apiKey: data.apiKey,
          },
        });

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteUsers(_, { call, select, put }) {
      try {
        const { currentUser } = yield select(({ userModel }) => ({
          currentUser: userModel.currentUser,
        }));

        yield call(deleteUsers, currentUser);

        yield put({ type: 'getUsers' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getUsersAdminUsernameList(_, { call, put }) {
      try {
        const data = yield call(getUsersAdminUsernameList);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *getUsersInstructorList(_, { call, put }) {
      try {
        const data = yield call(getUsersInstructorList);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *getUsersVideoNotificationList(_, { call, put }) {
      try {
        const data = yield call(getUsersVideoNotificationList);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *onCurrentUserChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentUser: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    *onRefineParamsChange({ payload }, { select, put }) {
      const { refineParams } = yield select(({ userModel }) => ({
        refineParams: { ...userModel.refineParams, ...payload },
      }));

      yield put({ type: 'getUsers', payload: refineParams });

      yield put({
        type: 'setState',
        payload: { refineParams },
      });
    },
    /* Matomo */
    *trackUserEvent({ payload }, { select }) {
      const { profile } = yield select(({ userModel }) => ({
        profile: userModel.profile,
      }));

      const { action, data = {} } = payload;
      _paq.push([
        'trackEvent',
        'User',
        action,
        JSON.stringify({
          domain: profile.portalDomain,
          role: profile.role,
          ...data,
        }),
      ]);
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
