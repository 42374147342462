import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import { DATE_TIME_FORMAT, DOMAIN_LIST } from '@/constants';
import { getDuration } from '@/utils/duration';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Popover,
  Select,
  Spin,
  Table,
} from 'antd';
import { connect } from 'dva';
import moment from 'moment';
import React from 'react';
import ReactJsonViewer from 'react-json-view';

import './index.less';

const jsonToCSV = (val) => {
  let str = '';

  if (Array.isArray(val)) {
    val.forEach((data, index) => {
      str += `${index + 1}. ${jsonToCSV(data)}`;
    });
  } else if (val && typeof val === 'object') {
    Object.entries(val).forEach(([newKey, newVal]) => {
      str += `${newKey}: ${jsonToCSV(newVal)}`;
    });
  } else {
    str += `${val}; `;
  }
  return str;
};

class AnalyticsGenerateReport extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      userModel: { analyticsAdminRole, profile: { memberOfPortalDomains } },
    } = this.props;

    // Event matrix for visits report;
    dispatch({ type: 'analyticsModel/getAnalyticsEventsMatrix' });
    // Event matrix for visits report;
    dispatch({ type: 'analyticsModel/getAnalyticsReportTypes' });
    // API tracker field lists for API tracker counts report and API tracker archive counts report;
    dispatch({ type: 'analyticsModel/getAnalyticsApiTrackerFieldLists' });
  }

  renderSider = () => {
    const {
      analyticsModel: { refineParams, eventsMatrix, fieldLists, reportTypes },
      userModel: { analyticsAdminRole, profile: { memberOfPortalDomains } },
      dispatch,
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onFinish={(values) => {
            const { reportType, startDate, endDate, ...payload } = values;

            if (startDate) payload.startDate = startDate.format('YYYY-MM-DD');
            if (endDate) payload.endDate = endDate.format('YYYY-MM-DD');

            dispatch({
              type: `analyticsModel/getAnalytics${reportType[0].toUpperCase() + reportType.slice(1)
                }`,
              payload,
            });

            dispatch({
              type: 'analyticsModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }}
        >
          <Form.Item
            label="Report Type"
            name="reportType"
            rules={[{ required: true }]}
          >
            <Select options={reportTypes} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(preValue, curValue) => preValue.reportType !== curValue.reportType}
          >
            {({ getFieldValue }) => {
              const reportType = getFieldValue('reportType');

              if (reportType === 'visits') {
                return (
                  <>
                    <Form.Item
                      label="User Email Domain"
                      name="domain"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Username"
                      name="username"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Event Category"
                      name="category"
                    >
                      <Select
                        allowClear
                        options={Object.keys(eventsMatrix).map((item) => ({
                          label: item,
                          value: item,
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(preValue, curValue) => preValue.category !== curValue.category}
                    >
                      {({ getFieldValue }) => {
                        const category = getFieldValue('category');

                        let actionList = [];
                        if (category) {
                          actionList = eventsMatrix[category];
                        } else {
                          Object.values(eventsMatrix).forEach((item) => {
                            actionList = [...actionList, ...item];
                          });
                        }

                        return (
                          <Form.Item
                            label="Event Action"
                            name="action"
                          >
                            <Select
                              allowClear
                              options={actionList.map((item) => ({ label: item, value: item }))}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      label="Start Date"
                      name="startDate"
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="End Date"
                      name="endDate"
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="Max Visits"
                      name="maxVisits"
                    >
                      <Input />
                    </Form.Item>
                  </>
                );
              }

              if (reportType === 'labsBooked') {
                return (
                  <>
                    {analyticsAdminRole && (
                      <Form.Item
                        label="Portal Domain"
                        name="portalDomain"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={memberOfPortalDomains.map((item) => ({ label: item, value: item }))}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      label="Start After"
                      name="startDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="End Before"
                      name="endDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="User Email Domain"
                      name="userDomain"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Booking Owner"
                      name="bookingOwner"
                    >
                      <Input />
                    </Form.Item>
                  </>
                );
              }

              if (reportType === 'labsBookedCounts') {
                return (
                  <>
                    {analyticsAdminRole && (
                      <Form.Item
                        label="Portal Domain"
                        name="portalDomain"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={memberOfPortalDomains.map((item) => ({ label: item, value: item }))}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      label="Start After"
                      name="startDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="End Before"
                      name="endDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="User Email Domain"
                      name="userDomain"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Booking Owner"
                      name="bookingOwner"
                    >
                      <Input />
                    </Form.Item>
                  </>
                );
              }

              if (
                reportType === 'viewDocumentsCounts' ||
                reportType === 'watchedVideosCounts' ||
                reportType === 'documentationVideoLinkCounts' ||
                reportType === 'watchedDocumentationVideoCounts'
              ) {
                return (
                  <>
                    <Form.Item
                      label="Start After"
                      name="startDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="End Before"
                      name="endDate"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                  </>
                );
              }

              if (reportType === 'enrolledUsers') {
                return (
                  <>
                    <Form.Item
                      label="User Email Domain"
                      name="emailDomain"
                    >
                      <Input />
                    </Form.Item>
                  </>
                );
              }

              if (reportType === 'apiTrackerCounts' || reportType === 'apiTrackerArchiveCounts') {
                return (
                  <>
                    <Form.Item
                      label="Tracker Field"
                      name="field"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        options={[
                          { label: 'requestPath', value: 'requestPath' },
                          ...Object.keys(fieldLists).map((item) => ({
                            label: item,
                            value: item,
                          })),
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Start After"
                      name="startDate"
                      rules={[{ required: reportType === 'apiTrackerArchiveCounts' }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="End Before"
                      name="endDate"
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(preValue, curValue) => preValue.field !== curValue.field}
                    >
                      {({ getFieldValue }) => {
                        if (!getFieldValue('field')) return;

                        return Object.keys(fieldLists).map((field) => (
                          <Form.Item
                            key={field}
                            label={field}
                            name={field}
                          >
                            <AutoComplete
                              allowClear
                              filterOption
                              options={fieldLists[field].map((item) => ({
                                label: item,
                                value: item,
                              }))}
                            />
                          </Form.Item>
                        ));
                      }}
                    </Form.Item>
                  </>
                );
              }

              return null;
            }}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Generate
            </Button>
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderVisitsReport = () => {
    const {
      analyticsModel: { visitsReport },
    } = this.props;

    const columns = [
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (value) => value.length,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        render: (value) => getDuration(value * 1000),
      },
      {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render: (value) => `${value.country}, ${value.continent}`,
      },
    ];

    const innerColumns = [
      {
        title: 'Category',
        dataIndex: 'eventCategory',
        key: 'eventCategory',
      },
      {
        title: 'Action',
        dataIndex: 'eventAction',
        key: 'eventAction',
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Details',
        dataIndex: 'eventName',
        key: 'eventName',
        render: (value) => {
          try {
            return (
              <Popover
                content={
                  <ReactJsonViewer
                    name={false}
                    displayDataTypes={false}
                    enableClipboard={false}
                    displayObjectSize={false}
                    quotesOnKeys={false}
                    displayArrayKey={false}
                    src={JSON.parse(value)}
                  />
                }
              >
                <Button type="link">Show</Button>
              </Popover>
            );
          } catch (e) {
            return value;
          }
        },
      },
    ];

    return (
      <Collapse
        ghost
        bordered={false}
        className="report-container folder-collapse"
        expandIcon={({ isActive }) => (
          <span>
            {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
            {isActive ? <FolderOpenOutlined /> : <FolderOutlined />}
          </span>
        )}
      >
        {Object.keys(visitsReport).map((domain) => (
          <Collapse.Panel
            key={domain}
            header={domain}
          >
            <Collapse
              ghost
              bordered={false}
              className="folder-collapse"
              expandIcon={({ isActive }) => (
                <span>
                  {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
                  {isActive ? <FolderOpenOutlined /> : <FolderOutlined />}
                </span>
              )}
            >
              {Object.keys(visitsReport[domain]).map((username) => (
                <Collapse.Panel
                  key={username}
                  header={username}
                >
                  <Table
                    size="small"
                    rowKey="id"
                    pagination={false}
                    dataSource={visitsReport[domain][username]}
                    columns={columns}
                    expandable={{
                      rowExpandable: (record) => record.actions && record.actions.length > 0,
                      expandedRowRender: (record) => (
                        <Table
                          className="inner-table"
                          size="small"
                          pagination={false}
                          dataSource={record.actions}
                          columns={innerColumns}
                        />
                      ),
                    }}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  };

  renderLabsBookedReport = () => {
    const {
      analyticsModel: { labsBookedReport },
    } = this.props;

    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        className: 'description-column',
        render: (value) => value && value.join('\n'),
      },
      {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
      },
      {
        title: 'End Time',
        dataIndex: 'endTime',
        key: 'endTime',
        render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Creator',
        dataIndex: 'bookingCreator',
        key: 'bookingCreator',
      },
      {
        title: 'Owner',
        dataIndex: 'bookingOwner',
        key: 'bookingOwner',
      },
      {
        title: 'Time',
        dataIndex: 'bookedTime',
        key: 'bookedTime',
      },
    ];

    return (
      <Table
        bordered
        size="small"
        rowKey="startTime"
        pagination={false}
        dataSource={labsBookedReport}
        columns={columns}
      />
    );
  };

  renderCountsReport = () => {
    const {
      analyticsModel,
      analyticsModel: { refineParams },
    } = this.props;

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '80%',
        ellipsis: true,
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
      },
    ];

    return (
      <Table
        bordered
        size="small"
        rowKey="description"
        pagination={false}
        dataSource={analyticsModel[`${refineParams.reportType}Report`]}
        columns={columns}
      />
    );
  };

  renderWatchedDocumentationVideoCountsReport = () => {
    const {
      analyticsModel: { watchedDocumentationVideoCountsReport },
    } = this.props;

    const columns = [
      {
        title: 'Product Type',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Product Type Count',
        dataIndex: 'count',
        key: 'count',
      },
    ];

    const innerColumns = [
      {
        title: 'Video Name',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Video Count',
        dataIndex: 'count',
        key: 'count',
      },
    ];

    return (
      <Table
        bordered
        className="table-row-expand-no-border"
        size="small"
        rowKey="description"
        pagination={false}
        dataSource={watchedDocumentationVideoCountsReport}
        columns={columns}
        expandable={{
          rowExpandable: (record) => record.videos && record.videos.length > 0,
          expandedRowRender: (record) => (
            <Table
              className="inner-table"
              size="small"
              rowKey="description"
              pagination={false}
              dataSource={record.videos}
              columns={innerColumns}
            />
          ),
        }}
      />
    );
  };

  renderEnrolledUsersReport = () => {
    const {
      analyticsModel: { enrolledUsersReport },
    } = this.props;

    const columns = [
      {
        title: 'Name',
        key: 'name',
        render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Company',
        dataIndex: 'parentAccountName',
        key: 'parentAccountName',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Member of Domains',
        dataIndex: 'memberOfPortalDomains',
        key: 'memberOfPortalDomains',
        render: (record) => record && record.join(', '),
      },
    ];

    return (
      <Table
        bordered
        size="small"
        pagination={false}
        dataSource={enrolledUsersReport}
        columns={columns}
      />
    );
  };

  getDownloadLink = () => {
    const {
      analyticsModel: {
        refineParams,
        visitsReport,
        labsBookedReport,
        enrolledUsersReport,
        watchedDocumentationVideoCountsReport,
      },
      analyticsModel,
    } = this.props;

    let csvData = [];

    if (refineParams.reportType === 'visits') {
      const header = [
        'Email Domain',
        'Username',
        'Duration',
        'Start Time',
        'Location',
        'Event Category',
        'Event Action',
        'Time',
        'Details',
      ];
      csvData.push(header.join(','));

      Object.keys(visitsReport).forEach((domain) => {
        let l1 = true;

        Object.keys(visitsReport[domain]).forEach((username) => {
          let l2 = true;

          visitsReport[domain][username].forEach((visit) => {
            let l3 = true;
            (visit.actions.length > 0 ? visit.actions : [{}]).forEach((action) => {
              const formatDetails = (str) => {
                try {
                  return jsonToCSV(JSON.parse(str));
                } catch (e) {
                  return str;
                }
              };

              const content = [
                l1 ? domain : '',
                l2 ? username : '',
                l3 ? getDuration(visit.duration * 1000) : '',
                l3 && visit.startTime ? moment(visit.startTime).format(DATE_TIME_FORMAT) : '',
                l3 && visit.location
                  ? `"${visit.location.country}, ${visit.location.continent}"`
                  : '',
                action.eventCategory,
                action.eventAction,
                action.time ? moment(action.time).format(DATE_TIME_FORMAT) : '',
                action.eventName ? `"${formatDetails(action.eventName)}"` : '',
              ];

              l1 = false;
              l2 = false;
              l3 = false;

              csvData.push(content.join(','));
            });
          });
        });
      });
    } else if (refineParams.reportType === 'labsBooked') {
      const header = [
        'Title',
        'Description',
        'Start Time',
        'End Time',
        'Status',
        'Creator',
        'Owner',
        'Time',
      ];
      csvData.push(header.join(','));
      labsBookedReport.forEach((data) => {
        const content = [
          data.title,
          data.description ? data.description.join('; ') : '',
          data.startTime ? moment(data.startTime).format(DATE_TIME_FORMAT) : '',
          data.endTime ? moment(data.endTime).format(DATE_TIME_FORMAT) : '',
          data.status,
          data.bookingCreator,
          data.bookingOwner,
          data.bookedTime,
        ];
        csvData.push(content.join(','));
      });
    } else if (refineParams.reportType === 'enrolledUsers') {
      const header = ['Name', 'Username', 'Email', 'Company', 'Role', 'Member of Domains'];
      csvData.push(header.join(','));
      enrolledUsersReport.forEach((data) => {
        const content = [
          `${data.firstName} ${data.lastName}`,
          data.username,
          data.email,
          data.parentAccountName,
          data.role,
          data.memberOfPortalDomains ? `"${data.memberOfPortalDomains.join(', ')}"` : '',
        ];
        csvData.push(content.join(','));
      });
    } else if (
      refineParams.reportType === 'labsBookedCounts' ||
      refineParams.reportType === 'viewDocumentsCounts' ||
      refineParams.reportType === 'watchedVideosCounts' ||
      refineParams.reportType === 'documentationVideoLinkCounts' ||
      refineParams.reportType === 'apiTrackerCounts' ||
      refineParams.reportType === 'apiTrackerArchiveCounts'
    ) {
      const header = ['Description', 'Count'];
      csvData.push(header.join(','));
      analyticsModel[`${refineParams.reportType}Report`].forEach((data) => {
        const content = [`"${data.description}"`, data.count];
        csvData.push(content.join(','));
      });
    } else if (refineParams.reportType === 'watchedDocumentationVideoCounts') {
      const header = ['Product Type', 'Product Type Count', 'Video Name', 'Video Count'];
      csvData.push(header.join(','));
      watchedDocumentationVideoCountsReport.forEach((productType) => {
        const content = [`"${productType.description}"`, productType.count, '', ''];
        csvData.push(content.join(','));

        productType.videos &&
          productType.videos.forEach((video) => {
            const content = ['', '', `"${video.description}"`, video.count];
            csvData.push(content.join(','));
          });
      });
    }

    const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });

    return window.URL.createObjectURL(blob);
  };

  render() {
    const {
      analyticsModel: { refineParams, loading },
    } = this.props;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Metrics"
          color="#0271bc"
          title="Generate Analytics Report"
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page analytics-report-container">
            {loading ? (
              <Spin
                className="table-loading"
                tip="Generating your report... Please wait"
                size="large"
              />
            ) : (
              <div className="table-container">
                {refineParams.reportType === 'visits' && this.renderVisitsReport()}
                {refineParams.reportType === 'labsBooked' && this.renderLabsBookedReport()}
                {refineParams.reportType === 'enrolledUsers' && this.renderEnrolledUsersReport()}
                {(refineParams.reportType === 'labsBookedCounts' ||
                  refineParams.reportType === 'viewDocumentsCounts' ||
                  refineParams.reportType === 'watchedVideosCounts' ||
                  refineParams.reportType === 'documentationVideoLinkCounts' ||
                  refineParams.reportType === 'apiTrackerCounts' ||
                  refineParams.reportType === 'apiTrackerArchiveCounts') &&
                  this.renderCountsReport()}
                {refineParams.reportType === 'watchedDocumentationVideoCounts' &&
                  this.renderWatchedDocumentationVideoCountsReport()}
                {refineParams.reportType && (
                  <div className="table-footer">
                    <div />
                    <div>
                      <Button
                        type="primary"
                        htmlType="a"
                        download="Report.csv"
                        href={this.getDownloadLink()}
                      >
                        <AppIcon name="export" />
                        Export as CSV
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

export default connect(({ analyticsModel, userModel }) => ({ analyticsModel, userModel }))(
  AnalyticsGenerateReport
);
