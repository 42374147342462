import Cookie from 'js-cookie';
import { routerRedux } from 'dva/router';
import { CEC, ROUTES, SINGLE_PAGES, PAGES_WITHOUT_REDIRECT } from '@/constants';
import { message } from 'antd';
import { getAnalyticsPlatformInfo, patchAuthSwitchDomain, patchAuthLogout } from '@/services';
import { getSearchParam, formatPlatformInfo } from '@/utils';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const model = {
  namespace: 'appModel',
  state: {},
  subscriptions: {
    setup({ dispatch, history }) {
      let prevLocation = history.location;

      // Redirect '/' page to '/cec/home' since the authorization cookie is set with 'path=/cec'
      if (prevLocation.pathname === '/') {
        window.location.replace(ROUTES.HOME);
      }

      history.listen(() => {
        if (getSearchParam('T') || getSearchParam('R')) {
          dispatch(routerRedux.push({ pathname: ROUTES.ERROR, search: history.location.search }));
        }
        dispatch({
          type: 'setState',
          payload: {
            singlePage: SINGLE_PAGES.find((e) => history.location.pathname.indexOf(e) > -1),
          },
        });

        /* Matomo */
        if (window._paq) {
          // Let Matomo know whenever the URL changes.
          _paq.push(['setReferrerUrl', prevLocation.pathname]);
          _paq.push(['setCustomUrl', window.location.href]);
          // Resetting previously set custom dimensions.
          Object.values(window._platformInfo.customDimensions).forEach((customDimensionId) =>
            _paq.push(['deleteCustomDimension', customDimensionId])
          );
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
        }

        prevLocation = history.location;
      });
    },
  },
  effects: {
    *init(_, { select, put }) {
      while (true) {
        const { validCookie } = yield select(({ appModel }) => ({
          validCookie: appModel.validCookie,
        }));
        const cookie = Cookie.get('authorization');

        if (validCookie !== cookie) {
          if (!validCookie) {
            yield put.resolve({ type: 'userModel/getUsersProfile' });

            /* Chatwoot */
            yield put({ type: 'chatbotModel/setupChatwoot' });
          } else if (!cookie) {
            yield put({ type: 'logout' });
          } else {
            window.location.reload();
          }

          /* Matomo */
          yield put({ type: 'setMatomoTracker' });
        } else if (!validCookie && !cookie) {
          if (!PAGES_WITHOUT_REDIRECT.find((reg) => reg.test(window.location.pathname))) {
            yield put(routerRedux.push({ pathname: ROUTES.HOME }));
          }
        }

        yield delay(10000);
      }
    },
    *setMatomoTracker(_, { call, select, put }) {
      try {
        const { profile } = yield select(({ userModel }) => ({
          profile: userModel.profile,
        }));
        window._paq = window._paq || [];
        window._platformInfo = { customDimensions: {} };
        window._platformInfo = formatPlatformInfo(yield call(getAnalyticsPlatformInfo));

        if (profile.username) {
          _paq.push(['setUserId', profile.username]);
          _paq.push([
            'setCustomDimension',
            window._platformInfo.customDimensions.PortalDomain,
            profile.portalDomain,
          ]);
          _paq.push([
            'setCustomDimension',
            window._platformInfo.customDimensions.UserRole,
            profile.role,
          ]);
          _paq.push([
            'setCustomDimension',
            window._platformInfo.customDimensions.UserDomain,
            profile.emailDomain,
          ]);
          if (!Cookie.get('login')) {
            yield put({ type: 'userModel/trackUserEvent', payload: { action: 'Login' } });
            Cookie.set('login', true, { path: CEC });
          }
        }

        if (getSearchParam('T') || getSearchParam('R')) {
          _paq.push([
            'setCustomDimension',
            window._platformInfo.customDimensions.DeniedLogin,
            window.location.search.substring(1),
          ]);
        }

        _paq.push([
          function () {
            window._platformInfo.visitorId = this.getVisitorId();
          },
        ]);

        _paq.push(['trackPageView']);
        _paq.push(['appendToTrackingUrl', '']);
        _paq.push(['enableLinkTracking']);

        /* Matomo Tracking Code */
        (function () {
          _paq.push(['setTrackerUrl', window._platformInfo.trackerUrl + 'matomo.php']);
          _paq.push(['setSiteId', window._platformInfo.siteId]);
          var d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
          g.async = true;
          g.src = window._platformInfo.trackerUrl + 'matomo.js';
          s.parentNode.insertBefore(g, s);
        })();
      } catch (e) {
        yield put({ type: 'handleError', payload: e });
      }
    },
    *patchAuthSwitchDomain({ payload }, { call, put }) {
      try {
        yield call(patchAuthSwitchDomain, {
          username: payload.username,
          portalDomain: payload.portalDomain,
        });

        window.location.reload();

        /* Matomo */
        yield put({ type: 'userModel/trackUserEvent', payload: { action: 'Switch Domain' } });
      } catch (e) {
        yield put({ type: 'handleError', payload: e });
      }
    },
    *handleError({ payload = {} }) {
      let errMessage = payload.message;
      if (Array.isArray(errMessage)) {
        errMessage = errMessage.join('; ');
      }
      yield message.error(errMessage);
    },
    *globalErrorHandler({ payload }, { put }) {
      if (payload.statusCode === 401 || payload.statusCode === 403) {
        Cookie.remove('authorization');
      } else {
        yield put({ type: 'handleError', payload });
      }
    },
    *logout(_, { call, put }) {
      if (Cookie.get('authorization')) {
        yield call(patchAuthLogout);
      }

      /* Chatwoot */
      yield put.resolve({ type: 'chatbotModel/toggleConversationStatus', payload: 'resolved' });

      Object.keys(Cookie.get()).forEach((cookieName) => {
        Cookie.remove(cookieName);
        Cookie.remove(cookieName, { path: CEC });
      });
      window.location.replace(ROUTES.HOME);

      /* Matomo */
      yield put.resolve({ type: 'userModel/trackUserEvent', payload: { action: 'Logout' } });
      _paq.push(['resetUserId']);
    },
    *setTableLoading({ payload }, { put }) {
      yield put({
        type: `${payload.modelName}/setState`,
        payload: {
          loading: payload.loading,
        },
      });
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
