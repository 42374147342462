import React from 'react';
import { connect } from 'dva';
import { Link } from 'dva/router';
import { Tooltip, Avatar } from 'antd';
import AppIcon from '@/components/app-icon';
import IconButton from '@/components/icon-button';
import Account from './account';
import Notification from './notification';
import EulaModal from './eula-modal';
import getToolbarItem from '@/constants/toolbar';
import { CEC, ROUTES } from '@/constants';

import './index.less';

class AppBar extends React.Component {
  state = {
    activeTab: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.appModel.validCookie && this.props.appModel.validCookie) {
      this.props.dispatch({
        type: 'userModel/getUsersAuthorizationToolbarIcons',
      });
      this.props.dispatch({
        type: 'contentModel/getCategories',
      });
      this.props.dispatch({
        type: 'contentModel/getEditCategories',
      });
      this.getContentSubCategories();
    }

    if (prevState.activeTab !== 'doc_lab' && this.state.activeTab === 'doc_lab') {
      this.getContentSubCategories();
    }
  }

  getContentSubCategories = () => {
    this.props
      .dispatch({
        type: 'contentModel/getSubCategoriesByParams',
        payload: { category: 'APIs' },
      })
      .then((data) => this.setState({ subCategoriesOfAPIs: data }));
    this.props
      .dispatch({
        type: 'contentModel/getSubCategoriesByParams',
        payload: { category: 'Samples' },
      })
      .then((data) => this.setState({ subCategoriesOfSamples: data }));
  };

  showMenu = (key, event) => {
    event.stopPropagation();
    if (key === this.state.activeTab) {
      this.hideMenu();
    } else {
      this.setState({ activeTab: key });
    }
  };

  hideMenu = (_, Component) => {
    this.setState({ activeTab: '' });

    if (Component) {
      this.setState({
        toolbarComponent: (
          <Component onCancel={() => this.setState({ toolbarComponent: undefined })} />
        ),
      });
    }
  };

  renderToolbar = () => {
    const {
      userModel: { toolbarIcons, profile },
      contentModel: { categories: contentCategories, editCategories: contentEditCategories },
      dispatch,
    } = this.props;
    const { activeTab, subCategoriesOfAPIs: APIs, subCategoriesOfSamples: Samples } = this.state;

    return toolbarIcons.map((icon) => {
      const toolbar = getToolbarItem(icon, {
        contentCategories,
        contentEditCategories,
        contentSubCategories: { APIs, Samples },
      });

      if (!toolbar) return null;
      return (
        <div key={toolbar.key}>
          <IconButton
            needHoverState
            className={
              activeTab === toolbar.key ||
              window.location.pathname.indexOf(`${CEC}/${toolbar.key}`) > -1
                ? 'active'
                : ''
            }
            icon={toolbar.icon}
            onClick={(event) => this.showMenu(toolbar.key, event)}
          >
            {toolbar.label}
          </IconButton>
          {activeTab === toolbar.key && (
            <div
              className="modal-wrap"
              onClick={this.hideMenu}
            >
              <div
                className="app-menu"
                onClick={(event) => event.stopPropagation()}
              >
                {(toolbar.columns || []).map((column, i) => (
                  <ul key={`column-${i}`}>
                    {(column || []).map((item) => (
                      <React.Fragment key={item.key}>
                        {item.header && <div className="menu-header">{item.header}</div>}
                        {(item.items || []).map((subitem) => {
                          if (subitem.hidden === true) {
                            return null;
                          }

                          if (
                            subitem.exclude &&
                            Object.entries(subitem.exclude).find(([key, val]) =>
                              (val || []).includes(profile[key])
                            )
                          ) {
                            return null;
                          }

                          return (
                            <li
                              className="menu-column-li"
                              key={subitem.key}
                            >
                              <IconButton
                                to={subitem.to}
                                icon={subitem.icon}
                                color={item.color}
                                desc={subitem.desc}
                                onClick={() => {
                                  subitem.action && subitem.action(dispatch);
                                  this.hideMenu(null, subitem.component);
                                }}
                              >
                                {subitem.label}
                              </IconButton>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const {
      appModel: { validCookie, singlePage },
      userModel: { profile },
      chatbotModel: { chatPlatformInfo },
      dispatch,
    } = this.props;
    const { toolbarComponent } = this.state;

    return singlePage ? null : (
      <div className="app-bar-container">
        <div
          className="app-bar"
          onClick={this.hideMenu}
        >
          <div className="app-bar-left">
            <Link
              className="app-logo"
              to={ROUTES.HOME}
            >
              <AppIcon
                svg="ciena"
                name="ciena-logo-white"
              />
              <span>Emulation Cloud</span>
            </Link>
            {this.renderToolbar()}
          </div>
          <div className="app-bar-right">
            {chatPlatformInfo.serviceUrl && (
              <IconButton
                icon="ai-assistant"
                onClick={() => {
                  dispatch({
                    type: 'chatbotModel/setState',
                    payload: { chatbotVisibile: true },
                  });
                }}
              />
            )}
            {profile.portalDomain && (
              <Tooltip title={profile.portalDomain}>
                <div className="domain-avatar">
                  <Avatar shape="square">{profile.portalDomain.charAt(0).toUpperCase()}</Avatar>
                </div>
              </Tooltip>
            )}
            {validCookie && <Notification dispatch={dispatch} />}
            <Account
              dispatch={dispatch}
              {...profile}
            />
          </div>
        </div>
        {profile.eulaUpTodate === false && <EulaModal />}
        {toolbarComponent}
      </div>
    );
  }
}

export default connect(({ appModel, userModel, contentModel, chatbotModel }) => ({
  appModel,
  userModel,
  contentModel,
  chatbotModel,
}))(AppBar);
