import CryptoJS from 'crypto-js';
import Cookie from 'js-cookie';
import { getChatPlatformInfo, postRequest, getRequest, postChatMessages } from '@/services';

const model = {
  namespace: 'chatbotModel',
  state: {
    loading: false,
    postMessagesLoading: false,
    error: '',
    chatPlatformInfo: {},
    chatbotMessages: [],
    chatbotVisibile: false,
    chatbotExpanded: false,
    chatbotDetached: false,
    // Chatbot API params:
    chatbotSourceId: '',
    chatbotPubsubToken: '',
    chatbotConversationId: null,
  },
  subscriptions: {},
  effects: {
    *setupChatwoot(_, { call, select, put }) {
      try {
        const { singlePage, profile } = yield select(({ appModel, userModel }) => ({
          singlePage: appModel.singlePage,
          profile: userModel.profile,
        }));

        if (singlePage || !profile.username) return;

        const chatPlatformInfo = yield call(getChatPlatformInfo);

        if (!chatPlatformInfo || !chatPlatformInfo.serviceUrl) return;

        yield put({ type: 'setState', payload: { chatPlatformInfo } });

        yield put.resolve({
          type: 'setState',
          payload: { chatbotSourceId: Cookie.get('cec_cb.source_id') },
        });

        yield put({ type: 'setContacts' });
        yield put({ type: 'setConversations' });
      } catch (e) {
        yield put({ type: 'appMode/handleError', payload: e });
      }
    },
    *setContacts(_, { call, select, put }) {
      try {
        const { chatPlatformInfo, chatbotSourceId } = yield select(({ chatbotModel }) => ({
          chatPlatformInfo: chatbotModel.chatPlatformInfo,
          chatbotSourceId: chatbotModel.chatbotSourceId,
        }));

        if (!chatbotSourceId) return;

        const { pubsub_token } = yield call(
          getRequest,
          `${chatPlatformInfo.serviceUrl}/public/api/v1/inboxes/${chatPlatformInfo.inboxIdentifier}/contacts/${chatbotSourceId}`
        );

        yield put({ type: 'setState', payload: { chatbotPubsubToken: pubsub_token } });
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      }
    },
    *setConversations(_, { call, select, put }) {
      try {
        const { chatPlatformInfo, chatbotSourceId } = yield select(({ chatbotModel }) => ({
          chatPlatformInfo: chatbotModel.chatPlatformInfo,
          chatbotSourceId: chatbotModel.chatbotSourceId,
        }));

        if (!chatbotSourceId) return;

        const data = yield call(
          getRequest,
          `${chatPlatformInfo.serviceUrl}/public/api/v1/inboxes/${chatPlatformInfo.inboxIdentifier}/contacts/${chatbotSourceId}/conversations`
        );

        if (data.length && data[data.length - 1] && data[data.length - 1].status !== 'resolved') {
          yield put({
            type: 'setState',
            payload: {
              chatbotConversationId: data[data.length - 1].id,
              chatbotAccountId: data[data.length - 1].contact.account_id,
            },
          });
        }
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      }
    },
    *postContacts(_, { call, select, put }) {
      try {
        const { chatPlatformInfo, chatbotSourceId, profile } = yield select(
          ({ chatbotModel, userModel }) => ({
            chatPlatformInfo: chatbotModel.chatPlatformInfo,
            chatbotSourceId: chatbotModel.chatbotSourceId,
            profile: userModel.profile,
          })
        );

        if (chatbotSourceId) return;

        const { source_id, pubsub_token } = yield call(
          postRequest,
          `${chatPlatformInfo.serviceUrl}/public/api/v1/inboxes/${chatPlatformInfo.inboxIdentifier}/contacts`,
          {
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({
              identifier: profile.username,
              identifier_hash: CryptoJS.HmacSHA256(
                profile.username,
                chatPlatformInfo.userIdValidation
              ).toString(),
              email: profile.email,
              name: `${profile.firstName} ${profile.lastName}`,
              company_name: profile.parentAccountName,
            }),
          }
        );

        Cookie.set('cec_cb.source_id', source_id);
        yield put({
          type: 'setState',
          payload: { chatbotSourceId: source_id, chatbotPubsubToken: pubsub_token },
        });
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      }
    },
    *postConversations(_, { call, select, put }) {
      try {
        const { chatPlatformInfo, chatbotConversationId, chatbotSourceId } = yield select(
          ({ chatbotModel }) => ({
            chatPlatformInfo: chatbotModel.chatPlatformInfo,
            chatbotConversationId: chatbotModel.chatbotConversationId,
            chatbotSourceId: chatbotModel.chatbotSourceId,
          })
        );

        if (chatbotConversationId) return;

        const { id, contact } = yield call(
          postRequest,
          `${chatPlatformInfo.serviceUrl}/public/api/v1/inboxes/${chatPlatformInfo.inboxIdentifier}/contacts/${chatbotSourceId}/conversations`
        );

        yield put({
          type: 'setState',
          payload: { chatbotConversationId: id, chatbotAccountId: contact.account_id },
        });
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      }
    },
    *getMessages(_, { call, select, put }) {
      try {
        yield put({ type: 'setState', payload: { loading: true, error: '' } });

        const { chatPlatformInfo, chatbotConversationId, chatbotSourceId } = yield select(
          ({ chatbotModel }) => ({
            chatPlatformInfo: chatbotModel.chatPlatformInfo,
            chatbotConversationId: chatbotModel.chatbotConversationId,
            chatbotSourceId: chatbotModel.chatbotSourceId,
          })
        );

        if (!chatbotSourceId || !chatbotConversationId) {
          yield put({ type: 'setState', payload: { chatbotMessages: [] } });

          return;
        }

        const chatbotMessages = yield call(
          getRequest,
          `${chatPlatformInfo.serviceUrl}/public/api/v1/inboxes/${chatPlatformInfo.inboxIdentifier}/contacts/${chatbotSourceId}/conversations/${chatbotConversationId}/messages`
        );

        yield put({ type: 'setState', payload: { chatbotMessages } });
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      } finally {
        yield put({ type: 'setState', payload: { loading: false } });
      }
    },
    *postMessages({ payload, callback }, { call, select, put }) {
      try {
        yield put({ type: 'setState', payload: { postMessagesLoading: true, error: '' } });

        yield put.resolve({ type: 'postContacts' });
        yield put.resolve({ type: 'postConversations' });

        const { chatbotConversationId, chatbotAccountId } = yield select(({ chatbotModel }) => ({
          chatbotConversationId: chatbotModel.chatbotConversationId,
          chatbotAccountId: chatbotModel.chatbotAccountId,
        }));

        // Post messages to Chatwoot directly
        // yield call(
        //   postRequest,
        //   `${chatPlatformInfo.serviceUrl}/public/api/v1/inboxes/${chatPlatformInfo.inboxIdentifier}/contacts/${chatbotSourceId}/conversations/${chatbotConversationId}/messages`,
        //   {
        //     headers: { 'Content-Type': 'application/json; charset=utf-8' },
        //     body: JSON.stringify(payload),
        //   }
        // );
        yield call(postChatMessages, {
          accountId: chatbotAccountId,
          conversationId: chatbotConversationId,
          ...payload,
        });

        callback && callback();
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      } finally {
        yield put({ type: 'setState', payload: { postMessagesLoading: false } });
      }
    },
    *toggleConversationStatus({ payload }, { call, select, put }) {
      try {
        yield put({ type: 'setState', payload: { postMessagesLoading: true, error: '' } });

        const { chatbotConversationId, chatbotAccountId } = yield select(({ chatbotModel }) => ({
          chatbotConversationId: chatbotModel.chatbotConversationId,
          chatbotAccountId: chatbotModel.chatbotAccountId,
        }));

        if (!chatbotAccountId || !chatbotConversationId) return;

        yield call(postChatMessages, {
          accountId: chatbotAccountId,
          conversationId: chatbotConversationId,
          status: payload,
        });

        if (payload === 'resolved') {
          yield put({ type: 'setState', payload: { chatbotConversationId: null } });
        }
      } catch (e) {
        yield put({ type: 'setState', payload: { error: e.error } });
      } finally {
        yield put({ type: 'setState', payload: { postMessagesLoading: false } });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
