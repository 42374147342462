import React from 'react';
import { Modal, Form, Button, Cascader, List } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import AppIcon from '@/components/app-icon';

import './index.less';

export default class LabConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: !props.value || props.value.length === 0 ? [{}] : cloneDeep(props.value),
      errors: [],
    };
    this.formRef = React.createRef();

    this.isCOMMUNITY = props.portalDomain === 'COMMUNITY';
  }

  validate = (value) => {
    const { dispatch } = this.props;

    let errors = [];

    // Check upload selection
    value.find((val) => val.file_upload && val.file_upload.type && !val.fileUpload) &&
      errors.push('Upload is required.');

    dispatch({ type: 'labModel/putScheduleVerifyBooking', payload: value }).then((e) => {
      e && errors.push(e.message);
      this.setState({ errors });
    });
  };

  getLabConfigOptions = (index) => {
    const { labConfigs } = this.props;
    if (!this.isCOMMUNITY) {
      return Object.keys(labConfigs).map((type) => ({
        config: type,
        _id: type,
        children: labConfigs[type],
      }));
    }
    const value = this.state.value.slice(0, index);

    // The lab configs are split in multiple categories: MCP, Community Pool, Fix, AIPA, PON
    const isMCP = (config) => config.type === 'MCP' || config.type === 'Navigator MC';
    const isPlannerPlus = (config) => config.type === 'PlannerPlus';
    const isFix = (config) => !isMCP(config) && !config.pool;
    const isPoolLayer0 = (config) => config.pool === 'Layer_0';
    const isPoolLayer1 = (config) => config.pool === 'Layer_1';
    const isPoolLayer2 = (config) => config.pool === 'Layer_2';
    const isAIPAMCP = (config) => config.pool === 'AIPA_MCP';
    const isAIPA = (config) => !isAIPAMCP(config) && config.pool && config.pool.includes('AIPA');
    const isPON = (config) => config.pool && config.pool.includes('PON');

    const validator = (config) => {
      // Check duplicate configs
      if (value.find((val) => val.config === config.config)) return false;
      if (isMCP(config)) {
        // Show MCPs if not selecting a MCP, PlannerPlus, MCP-AIPA or APIA config.
        return !value.find((val) => isMCP(val) || isPlannerPlus(val) || isAIPAMCP(val));
      }
      // Show Fix configs if not selecting a Fix, Pool, AIPA, MCP-AIPA or PON config.
      if (isFix(config)) {
        let valid = true;
        // Show PlannerPlus if not selecting a MCP.
        if (isPlannerPlus(config)) {
          valid = !value.find((val) => isMCP(val));
        }

        return (
          !value.find(
            (val) =>
              isFix(val) ||
              isAIPA(val) ||
              isAIPAMCP(val) ||
              isPoolLayer0(val) ||
              isPoolLayer1(val) ||
              isPoolLayer2(val) ||
              isPON(val)
          ) && valid
        );
      }
      // Show Pool Layer_0 if not selecting a Fix, AIPA or MCP-AIPA, PON, or Pool Layer_0 config.
      if (isPoolLayer0(config)) {
        return !value.find(
          (val) => isFix(val) || isAIPA(val) || isAIPAMCP(val) || isPoolLayer0(val) || isPON(val)
        );
      }
      // Show Pool Layer_1 if selecting a Pool Layer_0 config.
      if (isPoolLayer1(config)) {
        const isPoolLayer1Waveserver = (config) =>
          isPoolLayer1(config) && config.type.includes('Waveserver');
        let valid = true;
        // Show either Waveserver5 or Waveserver_Ai
        if (isPoolLayer1Waveserver(config)) {
          valid = !value.find((val) => isPoolLayer1Waveserver(val));
        }
        return value.find((val) => isPoolLayer0(val)) && valid;
      }
      // Show Pool Layer_2 if selecting a Pool Layer_0 and a Pool Layer_1 configs.
      if (isPoolLayer2(config)) {
        let valid = true;
        // Show SAOS_10 only if Waveserver5 selected in Layer 1
        if (config.type === 'SAOS_10') {
          valid = value.find((val) => isPoolLayer1(val) && val.type === 'Waveserver5');
        }
        return (
          value.find((val) => isPoolLayer0(val)) && value.find((val) => isPoolLayer1(val)) && valid
        );
      }

      // Show MCP-AIPA configs if not selecting a Fix, Pool, PON, MCP-AIPA or MCP config.
      if (isAIPAMCP(config)) {
        return !value.find(
          (val) =>
            isFix(val) ||
            isMCP(val) ||
            isAIPAMCP(val) ||
            isPoolLayer0(val) ||
            isPoolLayer1(val) ||
            isPoolLayer2(val) ||
            isPON(val)
        );
      }
      // Show APIA configs if not selecting a Fix, Pool, PON or AIPA config.
      if (isAIPA(config)) {
        return !value.find(
          (val) =>
            isFix(val) ||
            isAIPA(val) ||
            isPoolLayer0(val) ||
            isPoolLayer1(val) ||
            isPoolLayer2(val) ||
            isPON(val)
        );
      }
      // Show PON configs if not selecting a Fix, Pool, AIPA, MCP-AIPA, or same PON config.
      if (isPON(config)) {
        return !value.find(
          (val) =>
            isFix(val) ||
            isAIPA(val) ||
            isAIPAMCP(val) ||
            isPoolLayer0(val) ||
            isPoolLayer1(val) ||
            isPoolLayer2(val) ||
            (isPON(val) && val.pool === config.pool)
        );
      }

      return true;
    };

    let result = [];

    Object.keys(labConfigs).forEach((type) => {
      let children = [];
      labConfigs[type].forEach((labConfig) => {
        validator(labConfig) && children.push(labConfig);
      });

      children.length > 0 &&
        result.push({
          config: type,
          _id: type,
          children,
        });
    });

    return result;
  };

  render() {
    const { onChange, onCancel, dispatch } = this.props;
    let { value, errors } = this.state;
    const selectDisabled =
      errors.length > 0 ||
      !value[0].config ||
      // PON_MCMS and PON_SAOS should be selected together
      value.some((val) => val.pool === 'PON_MCMS') !== value.some((val) => val.pool === 'PON_SAOS');

    return (
      <Modal
        visible
        width={800}
        title="Select Lab Configs"
        closable={false}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={selectDisabled}
              onClick={() => {
                onChange && onChange(value.filter(({ config }) => config));
                onCancel && onCancel();
              }}
            >
              Select
            </Button>
          </>
        }
      >
        <Form ref={this.formRef}>
          <Form.List
            name="labConfigs"
            initialValue={value.map(({ config, fileUpload }) =>
              Object.assign({ config }, fileUpload ? { uploadName: fileUpload.uploadLabel } : {})
            )}
          >
            {(fields, { add, remove }) =>
              fields.map((field) => {
                const labConfigOptions = this.getLabConfigOptions(field.name);
                const fileUploadList =
                  value[field.name] && value[field.name].file_upload
                    ? value[field.name].fileUploadList
                    : null;
                const removeFollowers = (index) => {
                  if (!this.isCOMMUNITY) return;
                  value.splice(index + 1);
                  remove(
                    Array.from({ length: fields.length - index - 1 }, (_, i) => index + 1 + i)
                  );
                };

                return (
                  <List.Item
                    className="lab-config-list-item"
                    key={field.name}
                    actions={[
                      field.name ? (
                        <AppIcon
                          name="round-remove"
                          onClick={() => {
                            if (this.isCOMMUNITY) {
                              removeFollowers(field.name - 1);
                            } else {
                              value.splice(field.name, 1);
                              remove(field.name);
                            }
                            this.validate(value);
                          }}
                        />
                      ) : (
                        <AppIcon
                          disabled={
                            value.find((val) => !val.config) ||
                            this.getLabConfigOptions(fields.length).length === 0
                          }
                          name="round-add"
                          onClick={() => {
                            this.validate(value);
                            add();
                          }}
                        />
                      ),
                    ]}
                  >
                    <Form.Item
                      label="Config"
                      name={[field.name, 'config']}
                    >
                      <Cascader
                        dropdownClassName="lab-config-cascader-popup"
                        expandTrigger="hover"
                        fieldNames={{ label: 'config', value: '_id' }}
                        displayRender={(labels) => labels[labels.length - 1]}
                        onChange={(_, values) => {
                          const callback = () => {
                            value[field.name] = values ? values[1] : {};
                            removeFollowers(field.name);
                            this.validate(value);
                            this.setState({ value });

                            // Prompt a new field when the previous slot gets changed.
                            this.isCOMMUNITY &&
                              values &&
                              this.getLabConfigOptions(field.name + 1).length &&
                              add();
                          };

                          if (values && values[1].file_upload && values[1].file_upload.type) {
                            dispatch({
                              type: 'fileUploadModel/getFileUploadsUploadType',
                              payload: {
                                uploadType: values[1].file_upload.type,
                              },
                            }).then((data) => {
                              values[1].fileUploadList = data;

                              // Clear upload selection
                              this.formRef.current.setFieldValue(
                                ['labConfigs', field.name, 'uploadName'],
                                undefined
                              );

                              callback();
                            });
                          } else {
                            callback();
                          }
                        }}
                        options={labConfigOptions}
                      />
                    </Form.Item>
                    {fileUploadList && (
                      <Form.Item
                        label="Upload"
                        name={[field.name, 'uploadName']}
                      >
                        <Cascader
                          expandTrigger="hover"
                          fieldNames={{ label: 'uploadName', value: 'uploadFullName' }}
                          displayRender={(labels) => labels[labels.length - 1]}
                          onChange={(_, values) => {
                            value[field.name].fileUpload = values
                              ? {
                                  uploadDomain: values[0].uploadFullName,
                                  uploadName: values[1] && values[1].uploadFullName,
                                  uploadLabel: values[1] && values[1].uploadName,
                                }
                              : null;
                            this.validate(value);
                          }}
                          options={fileUploadList
                            .filter((item) => item.uploads && item.uploads.length)
                            .map((item) => ({
                              uploadName: item.domain,
                              uploadFullName: item.domain,
                              children: item.uploads,
                            }))}
                        />
                      </Form.Item>
                    )}
                  </List.Item>
                );
              })
            }
          </Form.List>
        </Form>
        <div className="highlight-info">
          {errors.map((item, index) => (
            <div key={`error-${index}`}>* {item}</div>
          ))}
        </div>
      </Modal>
    );
  }
}
