import { isEmpty, union } from 'lodash';
import {
  getMediaVideoPortalUrl,
  getMediaAdminVideoPortalUrl,
  getMediaMediaList,
  getMediaCategoryList,
  getMediaTagList,
  getMediaFixedFilters,
} from '@/services';

const model = {
  namespace: 'videoModel',
  state: {
    mediaList: [],
    categoryList: [],
    tagList: [],
    fixedFilters: {},
    categoryActiveKey: {},
  },
  subscriptions: {},
  effects: {
    *getMediaVideoPortalUrl(_, { call, put }) {
      try {
        const data = yield call(getMediaVideoPortalUrl);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return '';
      }
    },
    *getMediaAdminVideoPortalUrl(_, { call, put }) {
      try {
        const data = yield call(getMediaAdminVideoPortalUrl);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return '';
      }
    },
    *getMediaMediaList({ payload }, { call, select, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'videoModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ videoModel }) => ({
          refineParams: videoModel.refineParams,
        }));

        let {
          categoryActiveKey,
          createdAfter,
          categoriesMatchOr,
          tagsMultiLikeOr,
          fixedFilters = {},
          ...params
        } = payload || refineParams || {};
        if (createdAfter) {
          params.createdAfter = createdAfter.format('YYYY-MM-DD');
        }
        if (categoriesMatchOr || fixedFilters.categoriesMatchOr) {
          categoriesMatchOr =
            categoriesMatchOr && categoriesMatchOr.map((item) => item[item.length - 1]);
          const preset = union(...Object.values(fixedFilters.categoriesMatchOr || {}));
          params.categoriesMatchOr = union(categoriesMatchOr, preset).join(',');
        }
        if (tagsMultiLikeOr || fixedFilters.tagsMultiLikeOr) {
          const preset = union(...Object.values(fixedFilters.tagsMultiLikeOr || {}));
          params.tagsMultiLikeOr = union(tagsMultiLikeOr, preset).join(',');
        }

        let isRefineParamsEmpty = true;
        Object.values(params).forEach((val) => {
          isRefineParamsEmpty = isRefineParamsEmpty && isEmpty(val);
        });
        if (isRefineParamsEmpty) {
          yield put({
            type: 'setState',
            payload: { categoryActiveKey: categoryActiveKey || {} },
          });
        }

        const data = yield call(getMediaMediaList, params);

        yield put({
          type: 'setState',
          payload: { mediaList: data },
        });

        if (!isRefineParamsEmpty) {
          let categoryActiveKey = {};
          const getActiveKey = (obj, level) => {
            if (obj.subCategories && obj.subCategories.length > 0) {
              categoryActiveKey[`level${level}`] = [];
              obj.subCategories.forEach((subCategory) => {
                categoryActiveKey[`level${level}`].push(subCategory.id);
                getActiveKey(subCategory, level + 1);
              });
            }
          };
          getActiveKey({ subCategories: data }, 0);
          yield put({
            type: 'setState',
            payload: { categoryActiveKey },
          });
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'videoModel',
            loading: false,
          },
        });
      }
    },
    *getMediaCategoryList({ payload }, { call, put }) {
      try {
        const data = yield call(getMediaCategoryList, payload);

        yield put({
          type: 'setState',
          payload: {
            categoryList: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getMediaTagList({ payload }, { call, put }) {
      try {
        const data = yield call(getMediaTagList, payload);

        yield put({
          type: 'setState',
          payload: {
            tagList: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getMediaFixedFilters({ payload }, { call, put }) {
      try {
        const data = yield call(getMediaFixedFilters, payload);

        yield put({
          type: 'setState',
          payload: {
            fixedFilters: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
